<template lang="pug">
MessageModal(v-bind="$attrs" v-on="$listeners" wideButtons size="sm")
  template(#subtitle)
    .changeTariffModal-title Перейти на тариф
  template(#body)
    .changeTariffModal(v-if="tariff")
      .tariff-title
        span LabStory 
        span.text-primary {{ tariff.title }}
      span {{ tariff.descr }}
      .changeTariffModal-info
        .changeTariffModal-duration {{ duration.label }}
        .changeTariffModal-oldPrice {{ tariff.oldPrice }}₽
        .changeTariffModal-price {{ tariff.price }}₽
  template(#buttons)
    BaseBtn(@click="$emit('goToPay')" size="sm") Перейти к оплате
</template>

<script>
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
export default {
  components: { MessageModal },
  props: ['tariff', 'duration']
}
</script>

<style lang="scss">
.changeTariffModal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
.changeTariffModal-title {
  font-size: 12px;
  line-height: 15px;
  color: $grey-2;
  align-self: stretch;
  margin-bottom: 8px;
}

.changeTariffModal-info{
  background: $light-background;
  padding: 18px;
  border-radius: 18px;
  align-self: stretch;
  margin-bottom: -10px;
  margin-top: 12px;
  display: flex;
  gap: 29px;
}

.changeTariffModal-oldPrice{
  margin-left: auto;
  text-decoration: line-through;
}

.changeTariffModal-price{
  color: $accent-color;
  font-weight: 500;
}
</style>