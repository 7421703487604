<template lang="pug">
.tariffsPage.layout
  h4.h1.tariffsPage-title Тарифы
  .tariffsPage-header
    BaseTabs(:data="tabsData" @tabChange="activeTab = $event" :activeTab="activeTab").tariffs-tabs
    PaymentSummary
  .tariffs
    TarrifItem(v-for="tariff in tariffs" :tariff="tariff" :activeTariff="activeTariff" :key="tariff.name" @chooseTariff="chooseTariff")
  ChangeTariffModal(v-model="modalStatus.changeTariff" :tariff="tariffs[choosedTariff]" :duration="activeDuration" @goToPay="modalStatus.successPayment = true")
  SuccessPaymentModal(v-model="modalStatus.successPayment" :tariff="tariffs[choosedTariff]")
  FailPaymentModal(v-model="modalStatus.failPayment")
  DisablePaymentModal(v-model="modalStatus.disablePayment")
</template>

<script>
import BaseTabs from '@/components/BaseTabs.vue';
import TarrifItem from './TariffItem.vue'
import PaymentSummary from './PaymentSummary.vue'
import {tariffsData, durationTabs} from './tariffsData.js'

import ChangeTariffModal from './modals/ChangeTariffModal.vue'
import SuccessPaymentModal from './modals/SuccessPaymentModal.vue'
import FailPaymentModal from './modals/FailPaymentModal.vue'
import DisablePaymentModal from './modals/DisablePaymentModal.vue'

export default {
  components: {
    TarrifItem,BaseTabs,PaymentSummary,
        ChangeTariffModal,
    SuccessPaymentModal,
    FailPaymentModal,
    DisablePaymentModal
  },
  data(){
    return {
      activeTariff: 'mini',
      choosedTariff: 'mini',
      tariffs: tariffsData,
      activeTab: 3,
      tabsData: durationTabs,
      modalStatus: {
        changeTariff: false,
        successPayment: false,
        failPayment: false,
        disablePayment: false,
      }
    }
  },
  computed: {
    activeDuration(){
      return this.tabsData.find(tab => tab.name === this.activeTab)
    }
  },
  methods: {
    chooseTariff(tariffName){
      this.choosedTariff = tariffName;
      this.modalStatus.changeTariff = true
    }
  }
}
</script>

<style lang="scss">
.tariffsPage {
}
.tariffsPage-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @include media-down($breakpoint-md){
    flex-direction: column-reverse;
    gap: 40px
  }
}
.tariffsPage-title{
  padding-bottom: 24px;
}

.tariffs {
  display: flex;
  gap: 20px;
  @include media-down($breakpoint-md){
    overflow-x: scroll;
    width: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.tariffs-tabs{
  .q-tab {
    width: 170px;
  }
  @include media-down($breakpoint-md){
    overflow-x: scroll;
    width: 100%;
    padding-top: 5px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
