<template lang="pug">
.paymentSummary 
  .paymentSummary-error: icon(name="doc-progress-icon") 
  .paymentSummary-data
    .paymentSummary-status Платеж не прошел — 696 ₽
    .paymentSummary-cardInfo
      .paymentSummary-paymentDate 23 ноября 2021
      span |
      CardIcon
      .paymentSummary-cardNumber **** 1419
  BaseBtn(size="sm").paymentSummary-button(@click="$router.push({name: 'paymentPage'})")
    span 
      span История 
      span.gt-md платежей
    icon(name="next-icon3" color="#7C74E9")

</template>

<script>
import CardIcon from '@/components/CardIcon.vue'

export default {
  components: {
    CardIcon,
  }
}
</script>

<style lang="scss">
.paymentSummary {
  user-select: none;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 15px;
  color: $grey-2;
  padding: 6px;
  padding-left: 16px;
  border: 1px solid $light-stroke;
  border-radius: 18px;
}

.paymentSummary-cardInfo {
  gap: 10px;
  display: flex;
  color: $grey-4
}
.paymentSummary-data {
  margin-left: 16px;
  margin-right: 32px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  @include media-down($breakpoint-md){
    margin-right: 12px;
  }
}

.paymentSummary-button {
  color: $grey-1 !important;
  background: white !important;
}

.paymentSummary-error {

}
</style>