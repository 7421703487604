<template lang="pug">
.tariffFeature
  icon(:name="iconName")
  TooltipInfo(
    content-class="_feature" 
    anchor="bottom middle"
    max-width="227px")
    template(#target): .tariffFeature-title {{feature.title}}
    template(#text) Длинное пояснение к каждой фиче, чтобы точно было понятно
  .tariffFeature-meta
    span(v-if="feature.meta") {{feature.meta}} 
    span.text-primary(v-if="feature.extra") {{feature.extra}}
    template(v-if="feature.title === 'Таблетница'")
      span(style="color: #7C74E9") • 
      span Только в android-версии
</template>

<script>
import TooltipInfo from '@/components/UI/tooltip/TooltipInfo.vue';

const iconsObj = {
  full: 'ok1',
  part: 'ok2',
  no: 'notok'
}

export default {
  props: ['feature'],
  computed: {
    iconName(){
      return iconsObj[this.feature.access]
    }
  },
  components: {TooltipInfo}
}
</script>

<style lang="scss">
.tariffFeature {
  font-size: 13px;
  gap: 2px 12px;
  display: grid;
  grid-template-columns: 16px auto;
  cursor: pointer;
}

.tariffFeature-title {
  width: fit-content;
  border-bottom: 1px dashed $grey-4;
  line-height: 17px;
}

.tariffFeature-meta {
  font-weight: 500;
  grid-column: 2;
  display: flex;
  gap: 10px
}



</style>