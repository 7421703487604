<template lang="pug">
MessageModal(v-bind="$attrs" v-on="$listeners" hideOkButton size="sm")
  template(#subtitle)
    .successPaymentModal-title Ура! Ваш подписка на тариф успешно оплачена ✌️
  template(#body)
    .successPaymentModal-tariffCard
      .tariff-title
        span LabStory 
        span.text-primary {{ tariff.title }}
      .successPaymentModal-duration Активен до 23 ноября 2021
</template>

<script>
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
export default {
  components: { MessageModal },
  props: ['tariff', 'duration']
}
</script>

<style lang="scss">
.successPaymentModal {
  
}
.successPaymentModal-tariffCard {
  background:$light-background;
  border-radius: 18px;
  align-self: stretch;
  padding: 12px 16px;
}
</style>