export const tariffsData = {
  free: {
    name: 'free',
    title: 'Free',
    descr: 'Подойдет всем, кто хочет видеть свои результаты и не особо следить за своим здоровьем',
    price: 0,
    oldPrice: null,
    until: null,
    features: [
      { title: 'Андроид-версия приложения', access: 'full' },
      { title: 'Личный кабинет для пациентов ЛабСтори', access: 'full' },
      { title: 'Загрузка и оцифровка анализов', access: 'part', meta: '5 документов' },
      { title: 'Хранение мед. документов', access: 'part', meta: '5 документов' },
      { title: 'Просмотр динамики по биомаркеру', access: 'part', meta: 'За последний год' },
      { title: 'Семейный доступ', access: 'part', meta: '1 пользователь' },
      { title: 'Таблетница', access: 'part', meta: '1 активный курс' },
      { title: 'Делиться результатами с врачом', access: 'full' },
      {
        title: 'Расшифровка анализов и онлайн-чат с врачом',
        access: 'full',
        meta: 'Оплачивается отдельно',
        extra: '+99₽',
      },
      { title: 'Скидка на анализы в ЛаБстори и Панорама Мед', access: 'no' },
      { title: 'Витрина со скидками от партнеров', access: 'no' },
      { title: 'Описание биомаркеров', access: 'no' },
      { title: 'Модуль рекомендаций', access: 'no' },
      { title: 'База статей по биомаркеру', access: 'no' },
    ],
  },
  mini: {
    name: 'mini',
    title: 'Mini',
    descr: 'Подойдет всем, кто более делательно следит за своим здоровьем, но не нужна таблетница',
    price: 696,
    oldPrice: 870,
    until: 'До 23 ноября 2020 года',
    features: [
      { title: 'Андроид-версия приложения', access: 'full' },
      { title: 'Личный кабинет для пациентов ЛабСтори', access: 'full' },
      { title: 'Загрузка и оцифровка анализов', access: 'full', meta: '5 документов' },
      { title: 'Хранение мед. документов', access: 'full', meta: '5 документов' },
      { title: 'Просмотр динамики по биомаркеру', access: 'full', meta: 'За последний год' },
      { title: 'Семейный доступ', access: 'part', meta: '2 пользователь' },
      { title: 'Таблетница', access: 'part', meta: '1 активный курс' },
      { title: 'Делиться результатами с врачом', access: 'full' },
      {
        title: 'Расшифровка анализов и онлайн-чат с врачом',
        access: 'full',
        meta: 'Оплачивается отдельно',
        extra: '+99₽',
      },
      { title: 'Скидка на анализы в ЛаБстори и Панорама Мед', access: 'full', extra: '10%' },
      { title: 'Витрина со скидками от партнеров', access: 'full' },
      { title: 'Описание биомаркеров', access: 'full' },
      { title: 'Модуль рекомендаций', access: 'full' },
      { title: 'База статей по биомаркеру', access: 'no' },
    ],
  },
  plus: {
    name: 'plus',
    title: '+',
    descr: 'Подойдет всем, кто более делательно следит за своим здоровьем и нужна таблетница',
    price: 936,
    oldPrice: 1170,
    until: null,
    features: [
      { title: 'Андроид-версия приложения', access: 'full' },
      { title: 'Личный кабинет для пациентов ЛабСтори', access: 'full' },
      { title: 'Загрузка и оцифровка анализов', access: 'full', meta: 'Неограничено' },
      { title: 'Хранение мед. документов', access: 'full', meta: 'Неограничено' },
      { title: 'Просмотр динамики по биомаркеру', access: 'full', meta: 'Неограничено' },
      { title: 'Семейный доступ', access: 'full', meta: 'Неограничено' },
      { title: 'Таблетница', access: 'full', meta: 'Неограничено' },
      { title: 'Делиться результатами с врачом', access: 'full' },
      {
        title: 'Расшифровка анализов и онлайн-чат с врачом',
        access: 'full',
        meta: 'Оплачивается отдельно',
        extra: '+99₽',
      },
      { title: 'Скидка на анализы в ЛаБстори и Панорама Мед', access: 'full', extra: '15%' },
      { title: 'Витрина со скидками от партнеров', access: 'full' },
      { title: 'Описание биомаркеров', access: 'full' },
      { title: 'Модуль рекомендаций', access: 'full' },
      { title: 'База статей по биомаркеру', access: 'full' },
    ],
  },
};

export const durationTabs = [
  {
    name: 1,
    label: '1 месяц',
  },
  {
    name: 3,
    label: '3 месяца',
    badge: '- 20%',
  },
  {
    name: 6,
    label: '6 месяцев',
    badge: '- 25%',
  },
  {
    name: 12,
    label: '1 год',
    badge: '- 30%',
  },
];
