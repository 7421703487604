<template lang="pug">
.tariff
  .tariff-until {{tariff.until}}
  .tariff-title
    span LabStory 
    span.text-primary {{tariff.title}}
  .tariff-descr {{tariff.descr}}
  .tariff-card.tariffCard
    .tariffCard-price
      span {{tariff.price}} 
      span.tariffCard-priceSign ₽
      .tariffCard-oldPrice(v-if="tariff.oldPrice") {{tariff.oldPrice}}  ₽
    BaseBtn(size="sm" wide v-if="!isFreeTariff" @click="$emit('chooseTariff', tariff.name)").tariffCard-button Перейти на тариф
    .tariffCard-text(v-else-if="activeTariff !== 'free'") Чтобы перейти на бесплатный тариф,  отмените активную подписку.
  .tariff-features
    TariffFeature(v-for="(feature, i) in tariff.features" :feature="feature" :key="i")
</template>

<script>
import TariffFeature from './TariffFeature.vue'
export default {
  props: ['tariff', 'activeTariff'],
  computed: {
    isFreeTariff(){
      return this.tariff?.name === 'free'
    }
  },
  components: {
    TariffFeature
  }
}
</script>

<style lang="scss">
.tariff {
  background: white;
  padding: 20px 24px 59px;
  border: 1px solid $grey-6;
  border-radius: 40px;
  color: $grey-2;
  text-align: center;
  user-select: none;
  @include media-down($breakpoint-md){
    min-width: 100%;
  }
}
.tariff-title {
  font-size: 28px;
  line-height: 35px;
  font-weight: 500;
}
.tariff-until {
  margin-bottom: 16px;
  height: 16px;
  color: $accent-color;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.tariff-descr {
  font-size: 14px;
  line-height: 20px;
}

.tariff-features {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
}

.tariff-card {
  margin: 31px 0 24px;
}

.tariffCard {
  padding: 12px;
  background: #F9F9FC;
  border-radius: 25px;
  height: 136px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.tariffCard-text {
  font-size: 12px;
  line-height: 15px;
  color: $grey-4;
  margin-bottom: 14px;
  max-width: 230px;
}

.tariffCard-price {
  font-weight: 500;
  font-size: 46px;
  line-height: 58px;
  color: $accent-color;
  position: relative;
}
.tariffCard-priceSign {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
}
.tariffCard-oldPrice {
  position: absolute;
  font-size: 20px;
  line-height: 25px;
  color: $grey-1;
  top: 0;
  white-space: nowrap;
  transform: translateX(100%);
  right: 0;
  &:after {
    top: 50%;
    left: 0;
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    background: $accent-orange;
  }
}

</style>